<template>
  <div>

    <p v-if="errors.length">
      <b>Status:</b>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </p>

    <form @submit.stop.prevent="onSubmit" class="form">
     <div class="card-body">
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Informações Básicas:</h3>
      <div class="mb-15">
      	<div class="form-group row">
  <label class="col-lg-3 col-form-label">id</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="id" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">companies_id</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="companies_id" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">ban_title</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="ban_title" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">ban_image</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="ban_image" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">ban_position</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="ban_position" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">ban_active</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="ban_active" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">created</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="created" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">modified</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="modified" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	<div class="form-group row">
  <label class="col-lg-3 col-form-label">users_id</label>
  <div class="col-lg-6">
    <div class="input-group">
      <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
      <input v-model="users_id" require type="text" class="form-control" placeholder="Loja"/>
    </div>
  </div>
</div>
	
     </div>
     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>
         <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>
     </div>
    </form>
  </div>
  
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "store",
  props: {
    id: String,
    router_actions: Object
  },
  mounted() {

    if(this.$route.name == this.router_actions.route_edit){
      let id = this.$route.params.id;
      ApiService.get('Banners/get')
        .then(({data}) =>{
          this.errors.push(data.message);
          this.id = data.banners[0].id
					this.companies_id = data.banners[0].companies_id
					this.ban_title = data.banners[0].ban_title
					this.ban_image = data.banners[0].ban_image
					this.ban_position = data.banners[0].ban_position
					this.ban_active = data.banners[0].ban_active
					this.created = data.banners[0].created
					this.modified = data.banners[0].modified
					this.users_id = data.banners[0].users_id
					
        })
          .catch(({response}) => {
            this.errors.push(response.data.errors);
          });
          
    }else{
      this.FormReset();
    }
  },
  components: {},
  data() {
    return {

			companies_id: '',
			ban_title: '',
			ban_image: '',
			ban_position: '',
			ban_active: '',
			created: '',
			modified: '',
			users_id: '',
			
      errors: []
    };
  },
  methods: {
    FormReset() {
      {{cols_reset}}
    },

    onSubmit(e) {
      let id = null;
      if(this.errors.length){
        this.message = [];
      }
      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
        ApiService.post('Banners/update', {
          id: this.id,
				companies_id: this.companies_id,
				ban_title: this.ban_title,
				ban_image: this.ban_image,
				ban_position: this.ban_position,
				ban_active: this.ban_active,
				created: this.created,
				modified: this.modified,
				users_id: this.users_id
				
        })
          .then(({data}) => {
            this.errors.push(data.message);
            this.FormReset();
          })
            .catch(({response}) => {
              this.errors.push(response.data.errors);
            });
      }else{
        ApiService.post('Banners/add', {
          id: this.id,
				companies_id: this.companies_id,
				ban_title: this.ban_title,
				ban_image: this.ban_image,
				ban_position: this.ban_position,
				ban_active: this.ban_active,
				created: this.created,
				modified: this.modified,
				users_id: this.users_id
				
        })
          .then(({data}) => {
            this.errors.push(data.message);
            this.FormReset();
          })
            .catch(({response}) => {
              this.errors.push(response.data.errors);
            });  
      }

      

      e.preventDefault();
    }
  },
};
</script>
